<template>
  <div class="workspace__container__view">
    <div class="workspace__container__view__header">
      <div class="workspace__container__view__header__actions">
        <button @click="createOrEdit()">{{ $t("workspace.ad_agency_group.create_media-center") }}</button>
      </div>
      <div class="workspace__container__view__header__filter">
        <div class="container__inputs">
          <div class="settings-input">
            <input type="text" v-model="filterModel.code" :placeholder="$t('workspace.ad_agency_group.filter_for') + $t('workspace.ad_agency_group.code')">
          </div>
          <div class="settings-input">
            <input type="text" v-model="filterModel.name" :placeholder="$t('workspace.ad_agency_group.filter_for') + $t('workspace.ad_agency_group.name')">
          </div>
          <button :disabled="filterModel.name == '' && filterModel.code == ''" @click="filterModel.name = ''; filterModel.code = ''">{{ $t("workspace.clean_filter") }}</button>
        </div>
      </div>
    </div>


    <table class="workspace__container__view__table">
      <thead>
        <tr>
          <th>{{ $t("workspace.ad_agency_group.code") }}</th>
          <th>{{ $t("workspace.ad_agency_group.name") }}</th>
          <th class="actions">{{ $t("workspace.ad_agency_group.actions") }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="listMedias(filterModel).length > 0">
          <tr v-for="item in listMedias(filterModel)" :key="item.code">
            <td v-html="highlightText(item.code, filterModel.code)"></td>
            <td v-html="highlightText(item.name, filterModel.name)"></td>
            <td class="actions">
              <i class="material-icons" @click="createOrEdit(item)">edit</i>
              <i class="material-icons" @click="deleteItem(item)">delete</i>
            </td>
          </tr>
        </template>
        <tr v-if="listMedias(filterModel).length == 0 && !isFilterActive">
          <td colspan="3">{{$t('workspace.not_have_loaded')}} <strong>{{ $t("left_menu.commercial_config.ad_agency_group") }}</strong>. {{$t('workspace.please')}} <strong style="text-decoration: underline; cursor: pointer" @click="createOrEdit()">{{ $t("workspace.ad_agency_group.create_media-center") }}</strong></td>
        </tr>
        <tr v-if="listMedias(filterModel).length == 0 && isFilterActive">
          <td colspan="3">{{$t('workspace.none_results_with_filters')}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { computed, onMounted } from '@vue/runtime-core';
import highlightText from '@/plugins/Mixin/highlightText.js';

export default {
  mixins: [highlightText],
  setup() {
    const store = useStore();
    const listMedias = computed(() => filterModel => store.getters.adAgencyGroup_agencies(filterModel));
    const isFilterActive = computed(() => filterModel.value.code != '' || filterModel.value.name != '');
    const filterModel = ref({
      code: '',
      name: ''
    })

    onMounted(async () => {
      store.dispatch('loading_setActive', true);
      await store.dispatch('adAgencyGroup_getAll');
      store.dispatch('loading_setActive', false);
    });

    async function createOrEdit(item) {
      await store.dispatch('modal_setActive', {active: 'COMMERCIAL-CONFIG_AD-AGENCY-GROUP_CREATE-EDIT', props: item});
    }
    
    async function deleteItem(item) {
      await store.dispatch('modal_setActive', {active: 'COMMON_DELETE', props: item, actionDelete: 'adAgencyGroup_delete'});
    }

    return {
      listMedias,
      filterModel,
      isFilterActive,
      createOrEdit,
      deleteItem
    }
  }
}
</script>
<style lang="">
  
</style>